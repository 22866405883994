import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import {IconButton} from "@mui/material";
import {CaretLeft} from "@phosphor-icons/react";

const AppHeader = () => {
    const {
        appLang,
        uploadStage,
        returnToStage,
        setUploadStage,
        setReturnToStage,
        acceptTermsAndConditions,
        goToViewMyShares,
        handleSubmitData,
    } = useContext(GeneralContext);
    const content = language[appLang];

    const renderTitle = () => {
        let titleText;
        switch(uploadStage) {
            case 2:
                titleText = Utility.capitalize(content.KEEP_SNAPPING)
                break;
            case 3:
                titleText = Utility.capitalize(content.SHARE_MORE_WITH_US_OPTIONAL)
                break;
            case 4:
                titleText = Utility.capitalize(content.THANK_YOU)
                break;
            case 5:
                titleText = Utility.capitalize(content.VIEW_MY_SHARES)
                break;
            case 6:
                titleText = Utility.capitalize(content.TERMS_AND_CONDITIONS)
                break;
            case 7:
                titleText = Utility.capitalize(content.FAQ)
                break;
            default:
                titleText = "";
                break
        }

        return (
            <div className="additional-text">
                {titleText}
            </div>
        )
    }

    const renderNextAction = () => {
        switch(uploadStage) {
            case 2:
                return <div className="header-link" onClick={acceptTermsAndConditions}>
                    {Utility.capitalize(content.NEXT)}
                </div>
            case 3:
                return <div className="header-link" onClick={handleSubmitData}>
                    {Utility.capitalize(content.DONE)}
                </div>
            case 4:
                return <div className="header-link" onClick={goToViewMyShares}>
                    {Utility.capitalize(content.NEXT)}
                </div>
            case 5:
                return <div className="header-link" onClick={() => {
                    setReturnToStage(3);
                    setUploadStage(4);
                }}>
                    {Utility.capitalize(content.DONE)}
                </div>
            default:
                return null;
        }
    }

    const handleGoBack = () => {
        let newReturnToStage;
        switch (returnToStage) {
            case 2:
                newReturnToStage = 1;
                break;
            case 3:
                newReturnToStage = 2;
                break;
            case 4:
                newReturnToStage = 3;
                break;
            case 5:
                newReturnToStage = 4;
                break;
            default:
                newReturnToStage = 0;
        }

        setUploadStage(returnToStage);
        setReturnToStage(newReturnToStage);
    }

    return (
        <div className="app-header">
            <div className="header-content">
                {uploadStage !== 9
                    ? <IconButton
                        onClick={handleGoBack}
                    >
                        <CaretLeft/>
                    </IconButton>
                    : <div className="invisible-caret"/>
                }
                {renderTitle()}
                {renderNextAction()}
            </div>
            <div className="dividing-line"/>
        </div>

    );
};

export default AppHeader;