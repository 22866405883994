import React, {useContext, useRef} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import StyledUpload from "../shared-components/generic/StyledUpload";
import {IconButton} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";
import StyledCheckbox from "../shared-components/generic/StyledCheckbox";
import StyledButton from "../shared-components/generic/StyledButton";
import StyledList from "../shared-components/generic/StyledList";
import {ModalContext} from "../contexts/ModalContext";

const KeepSnapping = () => {
    const {
        appLang,
        currentUploads,
        hasAcceptedTerms,
        hasBeenAskedToShareVideo,
        setUploadStage,
        setReturnToStage,
        setCurrentUploads,
        setHasAcceptedTerms,
        uploadMedia,
        goToShareMore,
        secondIncentive,
        checkUploadsIncludeVideos,
    } = useContext(GeneralContext);
    const {setVideoNudgeModalOpen} = useContext(ModalContext);
    const content = language[appLang];
    const uploadNewMediaRef = useRef();

    const handleDeleteImage = (index) => {
        const updatedCurrentUploads = [...currentUploads];
        const imageToDelete = updatedCurrentUploads[index];
        const urlToRevoke = imageToDelete.tempUrl;

        updatedCurrentUploads.splice(index, 1);
        setCurrentUploads(updatedCurrentUploads);

        URL.revokeObjectURL(urlToRevoke);
    }

    const renderCurrentUploads = () => {
        const mediaToRender = [];
        currentUploads.forEach((uploadObj, index) => {
            mediaToRender.push(
                <div className="media-box" key={`media-upload-${index}`}>
                    <img src={uploadObj.tempUrl} alt={`Uploaded image ${index}`}/>
                    <IconButton onClick={() => handleDeleteImage(index)}>
                        <DeleteForever/>
                    </IconButton>
                </div>
            )
        });

        return mediaToRender;
    }

    const handleUploadMedia = (e) => {
        let files;
        if (!!e.target.files && typeof e.target.files === "object") {
            files = Object.values(e.target.files);
        }
        if (!files) {
            return
        }

        const sanitizedFiles = files.filter((file) => {
            return !!file && (file.type.startsWith('image/') || file.type.startsWith('video/')) && file.size > 0;
        });
        if (sanitizedFiles.length > 0) {
            uploadMedia(sanitizedFiles);
        }
    }

    const initiateUpload = () => {
        uploadNewMediaRef.current.click();
    }

    const goToTermsAndConditions = () => {
        setReturnToStage(2);
        setUploadStage(6);
    }

    const getTermsAndConditionsLabel = () => {
        const variableMap = new Map();
        const createTermsAndConditionsLink = () => {
            return (
                <div className="styled-link" onClick={goToTermsAndConditions}>
                    <span>{content.TERMS_AND_CONDITIONS}</span>
                    <div className="link-underline"/>
                </div>
            )
        };
        variableMap.set("termsAndConditions", createTermsAndConditionsLink());
        return Utility.createVariableSentenceSpan(Utility.capitalize(content.I_ACCEPT_THE_$$TERMS_AND_CONDITIONS$$), variableMap);
    }

    const handleGoToNext = () => {
        //TODO re-enable check for video - or gamify the process
        const hasVideoUpload = true//checkUploadsIncludeVideos();

        if (!hasVideoUpload && !hasBeenAskedToShareVideo) {
            setVideoNudgeModalOpen(true);
        } else goToShareMore();
    }

    return (
        <div className="main-container keep-snapping with-header">
            <div className="image-gallery">
                {renderCurrentUploads()}
                <StyledUpload
                    onClick={initiateUpload}
                />
            </div>
            <div className="small-text-regular tips-container">
                {!!secondIncentive
                    ? <div className={"variable-sentence"}>{Utility.capitalize(content.DONT_FORGET_WE_PICK_A_WINNER_SENTENCE)}</div>
                    : null
                }
                <div>{Utility.capitalize(content.TIPS)}:</div>
                <StyledList
                    items={[
                        Utility.capitalize(content.LEVERAGE_SHORT_VIDEOS),
                        Utility.capitalize(content.SHOWCASE_THE_VIBES_AND_AMBIANCE),
                        Utility.capitalize(content.MIX_IT_UP_WITH_VARIETY),
                    ]}
                />
            </div>
            <input
                id="additional-media-upload-input"
                type="file"
                multiple
                accept="image/*,video/*"
                style={{display: "none"}}
                onChange={handleUploadMedia}
                ref={uploadNewMediaRef}
            />
            <div className="bottom-section">
                <StyledCheckbox
                    className="additional-text"
                    checked={hasAcceptedTerms}
                    onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
                    label={getTermsAndConditionsLabel()}
                />
                <StyledButton
                    type="main"
                    onClick={handleGoToNext}
                    isDisabled={!hasAcceptedTerms || currentUploads.length < 1}
                >
                    {Utility.capitalize(content.NEXT)}
                </StyledButton>
            </div>
        </div>
    )
};

export default KeepSnapping;