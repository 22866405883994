import React from "react";
import "../appStyle.scss";

const MediaUpload = (props) => {
    const {
        mediaUrl,
        index
    } = props;

    return (
        <div className="media-box">
            <img id={`media-upload-img-${index}`} src={mediaUrl} alt={`Uploaded image ${index + 1}`}/>
        </div>
    );
};

export default MediaUpload;