import React, {createContext, useState} from "react";

export const ModalContext = createContext(null);

export const ModalProvider = (props) => {
    const [videoNudgeModalOpen, setVideoNudgeModalOpen] = useState(false);

    return (
        <ModalContext.Provider value={{
            videoNudgeModalOpen,

            setVideoNudgeModalOpen,
        }}>
            {props.children}
        </ModalContext.Provider>
    )
}