import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledButton = (props) => {
    const {
        className,
        children,
        type,   //"main", "secondary", "text"
        icon,
        isDisabled,
        noBorder,
        onClick,
        brandColorOverride,
    } = props;

    return (
        <button
            style={!!brandColorOverride ? {background: brandColorOverride} : null}
            className={`${className ? className + " " : ""}styled-button middle-text ${type}${noBorder ? " no-border" : ""}${isDisabled ? " disabled" : ""}`}
            onClick={isDisabled ? null : onClick}
        >
            {icon || null}
            {children}
        </button>
    );
};

export default StyledButton;