import React, {useContext, useRef} from 'react';
import ReactPlayer from "react-player";
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import cgcCover from "../assets/cgc-cover.png";
import cgcCoverVideo from "../assets/cgc-cover-video.mp4";
import StyledList from "../shared-components/generic/StyledList";
import StyledButton from "../shared-components/generic/StyledButton";

const LandingPage = () => {
    const {
        appLang,
        setUploadStage,
        setReturnToStage,
        uploadMedia,
        incentiveStatement,
        secondIncentive
    } = useContext(GeneralContext);
    const content = language[appLang];
    const uploadMediaRef = useRef();

    const items = [
        Utility.capitalize(content.SNAP_AND_UPLOAD_SENTENCE),
        Utility.capitalize(incentiveStatement),
        Utility.capitalize(content.WELL_SPREAD_LOVE_SENTENCE)
    ];
    if (!!secondIncentive) {
        items.push(Utility.capitalize(secondIncentive));
    }

    const handleUploadMedia = (e) => {
        let files;
        if (!!e.target.files && typeof e.target.files === "object") {
            files = Object.values(e.target.files);
        }
        if (!files) {
            return
        }

        const sanitizedFiles = files.filter((file) => {
            return !!file && (file.type.startsWith('image/') || file.type.startsWith('video/')) && file.size > 0;
        });
        if (sanitizedFiles.length > 0) {
            uploadMedia(sanitizedFiles);
        }

        setReturnToStage(1);
        setUploadStage(2);
    }

    const initiateUpload = () => {
        uploadMediaRef.current.click();
    }

    return (
        <div className="landing-page">
            <div className="cgc-cover-container">
                <ReactPlayer
                    height="100%"
                    width="100%"
                    autoPlay
                    loop
                    playing
                    muted
                    playsinline
                    className="cgc-cover"
                    url={cgcCoverVideo}
                    playIcon={null}
                    fallback={<img src={cgcCover} alt={content.IMAGE_OF_SOMEONE_TAKING_A_PICTURE_OF_FOOD}/>}
                />
            </div>
            <div className="main-container">
                <div className="big-text">
                    <span>{Utility.capitalize(content.LOVE_OUR_FOOD)}?</span>
                    <span>{Utility.capitalize(content.SHARE_THE_JOY)}!</span>
                </div>
                <StyledList
                    className="small-text"
                    numbered
                    items={items}
                />
                <input
                    id="initial-media-upload-input"
                    type="file"
                    multiple
                    accept="image/*,video/*"
                    style={{display: "none"}}
                    onChange={handleUploadMedia}
                    ref={uploadMediaRef}
                />
                <div className="bottom-section">
                    <StyledButton
                        type="main"
                        onClick={initiateUpload}
                    >
                        {Utility.capitalize(content.GET_STARTED)}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;