import {useState} from "react";
import {func} from "./Firebase";
import {httpsCallable} from "firebase/functions";
import enums from "../enums.json";
import ReactPlayer from "react-player";

const {responseEnum} = enums;
const letters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

// const colors = [
//     "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" ,
//     "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9" ,"#a4e43f" ,"#d298e2" ,"#6119d0",
//     "#d2737d" ,"#c0a43c" ,"#f2510e" ,"#651be6" ,"#79806e" ,"#61da5e" ,"#cd2f00" ,
//     "#9348af" ,"#01ac53" ,"#c5a4fb" ,"#996635","#b11573" ,"#4bb473" ,"#75d89e" ,
//     "#2f3f94" ,"#2f7b99" ,"#da967d" ,"#34891f" ,"#b0d87b" ,"#ca4751" ,"#7e50a8" ,
//     "#c4d647" ,"#e0eeb8" ,"#11dec1" ,"#289812" ,"#566ca0"
// ];

export class Utility {
    static sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    static objIsEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
    }

    static roundByDecimal(num, decPlaces) {
        const factor = Math.pow(10, decPlaces);
        return Math.round((Number(num) + Number.EPSILON) * factor) / factor
    }

    static capitalize(str) {
        if (!str) {
            return str;
        }
        let newStr = str[0].toUpperCase();
        return newStr + str.slice(1)
    }

    static sanitizeStr(str) {
        return str
            .replace(/[\/\\?%*:|"<>]/g, '')
            .replace(/\s+/g, '_')
            .trim();
    }

    static getInitials(firstName, lastName) {
        let initials = firstName ? firstName[0] : "";
        initials += lastName ? lastName[0].slice(-1) : "";
        initials = initials.toUpperCase();
        return initials;
    }

    static parseName(fullName) {
        const names = fullName.split(" ");
        const lastName = names.pop();
        const firstName = names.join(" ");
        return {
            firstName,
            lastName
        }
    }

    static makeId(len) {
        let id = "";
        for (let i = 0; i < len; i++) {
            id += letters[Math.floor(Math.random() * letters.length)]
        }
        return id
    };

    static renderDate_MMDDYYY(dateStr) {
        if (!dateStr) {
            return 'None';
        }
        const date = new Date(dateStr);
        const dayNumber = date.getDate();
        const monthNumber = date.getMonth() + 1;

        const day = dayNumber < 10 ? '0' + dayNumber : dayNumber;
        const month = monthNumber < 10 ? '0' + monthNumber : monthNumber;
        const year = date.getFullYear();
        return `${month}/${day}/${year}`
    }

    static renderDateTime_MMDDYYYY_HHMM(dateStr) {

        if (!dateStr) {
            return 'None';
        }
        const date = new Date(dateStr);
        return `${Utility.renderDate_MMDDYYY(dateStr)} ${Utility.renderTime_HHMM(date)}`;
    }

    static renderTime_HHMM(dateTimeStamp) {
        if (!dateTimeStamp) {
            return ""
        }
        const date = new Date(dateTimeStamp);

        const hoursNumber = date.getHours();
        const minutesStr = date.getMinutes();

        const hours = hoursNumber < 10 ? '0' + hoursNumber : hoursNumber;
        const minutes = minutesStr < 10 ? '0' + minutesStr : minutesStr;

        return `${hours}:${minutes}`;
    }

    static getDateFromTime_HHMM(timeStr) {
        if (!timeStr) {
            return ""
        }

        const [hours, minutes] = timeStr.split(":");
        const newDate = new Date();
        newDate.setHours(Number(hours));
        newDate.setMinutes(Number(minutes));

        return newDate;
    }

    static renderTime_12hour(dateStr) {
        if (!dateStr) {
            return ""
        }
        const date = new Date(dateStr);

        const hoursNumber = date.getHours();
        const minutesNumber = date.getMinutes();

        let meridian = "AM";
        let hours = hoursNumber;

        if (hoursNumber >= 12) {
            meridian = "PM"
        }

        if (hoursNumber >= 13) {
            hours = hoursNumber - 12;
        }

        const minutes = minutesNumber < 10 ? '0' + minutesNumber : minutesNumber;

        return `${hours}:${minutes} ${meridian}`;
    }

    static renderDate_YYYYMMDD(dateStr) {
        if (!dateStr) {
            return ""
        }
        const date = new Date(dateStr);
        const dayNumber = date.getDate();
        const monthNumber = date.getMonth() + 1;

        const day = dayNumber < 10 ? '0' + dayNumber : dayNumber;
        const month = monthNumber < 10 ? '0' + monthNumber : monthNumber;
        const year = date.getFullYear();
        return `${year}-${month}-${day}`
    }

    static dateFromNativeStr(dateStr) {
        if (!dateStr) {
            return new Date()
        }
        const year = Number(dateStr.slice(0, 4));
        const month = Number(dateStr.slice(5, 7)) - 1;
        const day = Number(dateStr.slice(8, 10));

        return new Date(year, month, day);
    }

    static getPathArray() {
        let pathStr = window.location.pathname;
        if (pathStr[pathStr.length - 1] === '/') {
            pathStr = pathStr.substring(0, pathStr.length - 1); // remove redundant last '/'
        }
        // noinspection UnnecessaryLocalVariableJS
        const pathArray = pathStr.split('/');
        return pathArray
    }

    static buildSortMethod(sortAscending, firstProp, type = 0) {   // 0 - normal, 1 - date, 2 - array
        switch (type) {
            case 0:
                return (listItemA, listItemB) => {
                    let itemA = listItemA[firstProp];
                    let itemB = listItemB[firstProp];
                    if (typeof itemA !== typeof itemB) {
                        itemA = String(itemA);
                        itemB = String(itemB);
                    }
                    if (itemA < itemB) {
                        return sortAscending ? -1 : 1;
                    }
                    if (itemA > itemB) {
                        return sortAscending ? 1 : -1;
                    }
                    return 0;
                }
            case 1:
                return (listItemA, listItemB) => sortAscending
                    ? new Date(listItemA[firstProp] || 0) - new Date(listItemB[firstProp] || 0)
                    : new Date(listItemB[firstProp] || 0) - new Date(listItemA[firstProp] || 0);
            case 2:
                return (listItemA, listItemB) => sortAscending
                    ? listItemA[firstProp].length - listItemB[firstProp].length
                    : listItemB[firstProp].length - listItemA[firstProp].length
            default:
                return () => {
                };
        }
    }

    static convertPhoneNumberToRef = (phoneNumberStr) => {
        return phoneNumberStr.split("-").join("").split(".").join("");
    }

    static formatPhoneNumber = (phoneNumber) => {
        // 'Live' format phone number as (XXX) XXX-XXXX
        const cleaned = phoneNumber.replace(/\D/g, "");
        let formattedValue = "";

        for (let i = 0; i < cleaned.length; i++) {
            if (i === 0) {
                formattedValue += `(${cleaned[i]}`;
            } else if (i === 3) {
                formattedValue += `) ${cleaned[i]}`;
            } else if (i === 6) {
                formattedValue += `-${cleaned[i]}`;
            } else {
                formattedValue += cleaned[i];
            }
        }

        return formattedValue;
    };

    static validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(email);
    };

    static getTextColorForBackground = (hex) => {
        if (!hex) {
            return "white";
        }

        // Convert hex to RGB first
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex.slice(1, 3), 16);
            g = parseInt(hex.slice(3, 5), 16);
            b = parseInt(hex.slice(5, 7), 16);
        }

        // Using the luminance formula to calculate brightness
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Return black for bright colors, white for dark colors
        return luminance > 0.8 ? "black" : "white";
    }

    static async httpCall(functionName, params) {
        try {
            const httpsRequest = httpsCallable(func, functionName);
            const response = await httpsRequest(params);

            if (!!response?.data && response.data.code === responseEnum.OK) {
                return {
                    code: response.data.code,
                    data: JSON.parse(response.data.data || "true")
                };
            } else {
                console.error(response?.data?.message);
                return {
                    code: response.data.code
                }
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    static getRandomNumber = (scale) => {
        return Math.floor(Math.random() * 2.1 * scale) - scale;
    }

    static splitArrayIntoMultiple = (mainArray, numOfSubArrays) => {
        const arrayOfSubArrays = Array.from({length: numOfSubArrays}, () => []);

        for (let i = 0; i < mainArray.length; i++) {
            for (let j = 0; j < numOfSubArrays; j++) {
                if (i % numOfSubArrays === j) {
                    arrayOfSubArrays[j].push(mainArray[i])
                }
            }
        }

        return arrayOfSubArrays;
    }

    static getAspectRatio(width, height) {
        function gcd(a, b) {
            return b === 0 ? a : gcd(b, a % b);
        }

        const divisor = gcd(width, height);

        const aspectRatioWidth = width / divisor;
        const aspectRatioHeight = height / divisor;

        return [aspectRatioWidth, aspectRatioHeight];
    }

    static createVariableSentenceSpan = (sentence, variableMap) => {
        const sentenceArray = sentence.split("$$");
        const sentenceSpans = sentenceArray.map((sentenceFragment, index) => {
            if (variableMap.has(sentenceFragment)) {
                return <span key={`variable-sentence-${index}`}>{variableMap.get(sentenceFragment)}</span>
            } else return <span key={`variable-sentence-${index}`}>{sentenceFragment}</span>
        });
        return (
            <span className="variable-sentence">
                {sentenceSpans}
            </span>
        )
    }

    static openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) {
            newWindow.opener = null;
        }
    }

    static renderMedia = (url, alt, className, eventColor) => {
        if (!url) {
            return null
        }

        const match = url.match(/\.(\w+)(\?|$)/);
        const fileExt =  match ? match[1].toLowerCase() : "";

        switch (fileExt) {
            case "mp4":
            case "webm":
            case "mov":
                return (
                    <ReactPlayer
                        height="100%"
                        width="100%"
                        autoPlay
                        loop
                        playing
                        muted
                        playsinline
                        className={className || ""}
                        url={url}
                        playIcon={null}
                        fallback={<img src={""} alt={alt}/>}
                        style={eventColor ? {backgroundColor: eventColor} : null}
                    />
                );
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
            case "heic":
                return (
                    <img
                        className={className || ""}
                        src={url}
                        alt={alt}
                        style={eventColor ? {backgroundColor: eventColor} : null}
                    />
                );
            default:
                return null;
        }
    }

    static fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };
}

export const useForceUpdate = () => {
    const [, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}