import React, {useContext, useState} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import StyledSnackbar from "../shared-components/generic/StyledSnackbar";
import MediaUpload from "./MediaUpload";
import {isMobile, isTablet} from "react-device-detect";
import googleLogo from "../shared-components/assets/google-logo.png";
import instagramLogo from "../shared-components/assets/instagram-logo.png";
import facebookLogo from "../shared-components/assets/facebook-logo.png";
import tiktokLogo from "../shared-components/assets/tiktok-logo.png";
import {ArrowRight} from "@phosphor-icons/react";
import enums from "../enums.json";

const {colors} = enums;

const instagramLink = "https://www.instagram.com/";
const facebookLink = "https://www.facebook.com/";
const tiktokLink = "https://www.tiktok.com/";

const ViewShares = () => {
    const {
        appLang,
        currentUploads,
        googleLink,
        handle,
    } = useContext(GeneralContext);
    const [errorMsgOpen, setErrorMsgOpen] = useState(false);
    const [successMsgOpen, setSuccessMsgOpen] = useState(false);
    const content = language[appLang];

    const handleCloseErrorMsg = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorMsgOpen(false);
    }

    const handleCloseSuccessMsg = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessMsgOpen(false);
    }

    const variableMap = new Map();
    variableMap.set("handle", handle);

    const renderSocialMediaCard = (logo, link, name) => {
        return (
            <div className="social-media-card" onClick={() => Utility.openInNewTab(link)}>
                <img src={logo} alt={name + " logo"}/>
                <span className="social-media-name">{name}</span>
                <ArrowRight color={colors.BLACK} weight="bold" size={20}/>
            </div>
        )
    }

    const renderLinks = () => {
        return (
            <div className="social-media-links">
                {renderSocialMediaCard(googleLogo, googleLink, content.GOOGLE_REVIEW)}
                {renderSocialMediaCard(instagramLogo, instagramLink, content.INSTAGRAM)}
                {renderSocialMediaCard(facebookLogo, facebookLink, content.FACEBOOK)}
                {renderSocialMediaCard(tiktokLogo, tiktokLink, content.TIK_TOK)}
            </div>
        )
    }

    const renderUploads = () => {
        const mediaToRender = [];
        currentUploads.forEach((uploadObj, index) => {
            if (!!uploadObj.tempUrl && !!uploadObj.imageId) {
                mediaToRender.push(<MediaUpload mediaUrl={uploadObj.tempUrl} index={index} key={`media-upload-${index}`}/>)
            }
        });

        return mediaToRender;
    }

    return (
        <div className="main-container with-header view-shares">
            <div className="big-text">
                {!!variableMap.get("handle")
                    ? Utility.createVariableSentenceSpan(Utility.capitalize(content.KEEP_SPREADING_THE_FOODIE_LOVE_AND_TAG_US_AT_$$HANDLE$$), variableMap)
                    : Utility.capitalize(content.KEEP_SPREADING_SENTENCE)
                }
            </div>
            {renderLinks()}
            <div className="small-text-regular">
                {Utility.capitalize(content.THANKS_FOR_SHARING_SENTENCE)}
            </div>
            {isMobile || isTablet
                ? <div className="additional-text info-text">
                    ({content.TAP_AND_HOLD_TO_SAVE_TO_YOUR_PHOTO_ALBUM})
                </div>
                : null
            }
            <div className="image-gallery">
                {renderUploads()}
            </div>
            <StyledSnackbar
                open={errorMsgOpen}
                onClose={handleCloseErrorMsg}
                autoHideDuration={4500}
                type="error"
                message={Utility.capitalize(content.THERE_WAS_A_PROBLEM_DOWNLOADING_YOUR_CONTENT_SENTENCE)}
            />
            <StyledSnackbar
                open={successMsgOpen}
                onClose={handleCloseSuccessMsg}
                autoHideDuration={4500}
                type="success"
                message={Utility.capitalize(content.URL_COPIED_TO_CLIPBOARD_SENTENCE)}
            />
        </div>
    );
};

export default ViewShares;